.services {
    height: 100vh;
    background-color: $red;
    position: relative;
    display: flex;
    align-items: end;
    
    &__container {
        padding-bottom: 180px;
        padding-left: 100px;
        padding-right: 100px;
        @media(max-width:$lg) {
            padding-bottom: 58px;
        }
        @media(max-width:$md) {
            padding-left: 18px;
            padding-right: 18px;
        }
        @media(max-width:$sm) {
            padding-bottom: 50px;

        }
    }
    &::before {
        content: "";
        position: absolute;
        height: 100%;
        top:0;
        right: 0;
        width: 60px;
        background-repeat: no-repeat;
        background: url('https://dev.yoho.pl/racoon/dist/images/wytarcia_pion_jasne.svg'), no-repeat;
        background-size: cover;
        background-position: right;
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        top:0;
        left: 0;
        height: 60px;
        background-repeat: no-repeat;
        background: url('https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg'), no-repeat;
        background-size: cover;
        // background-position: right;
    }
    * {
        color:white;
    }
    &__category {
        padding-bottom: 22px;
        text-transform: uppercase;
        font-family: $nimbus;
        font-size: 16px;
    }
    &__name {
        font-size: 56px;
        line-height: 56px;
        font-weight: 700;
        max-width: 1326px;
        @media(max-width:$lg) {
            font-size: 48px;
            line-height: 48px;
            max-width: 908px;
        }
        @media(max-width:$sm) {
            font-size: 28px;
            line-height: 28px;
        }
        .italic {
            font-family: "sofia-pro", sans-serif;
            font-weight: 300;
            font-style: italic;
        }
        .regular {
            font-weight: 400;
        }
    }
    &__subname {
      font-weight: 400;
      @media(max-width:$lg) {
        font-size: 28px;
        line-height: 28px;
    }
    @media(max-width:$sm) {
        font-size: 20px;
        line-height: 20px;
       max-width: 260px;
    }
    }
}