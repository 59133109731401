html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #F6F5F4;
  background-size: contain;
}

* {
  font-family: "foco";
}

p {
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}

div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

::selection {
  background-color: rgba(191, 0, 19, 0.4666666667);
  color: black;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #BF0012; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid black; /* creates padding around scroll thumb */
}

h1 {
  font-size: 92px;
  line-height: 92px;
}
@media (max-width: 1366px) {
  h1 {
    font-size: 72px;
    line-height: 72px;
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 38px;
    line-height: 38px;
  }
}
@media (max-width: 360px) {
  h1 {
    font-size: 32px;
    line-height: 32px;
  }
}

h2 {
  font-size: 60px;
  line-height: 60px;
}
@media (max-width: 1366px) {
  h2 {
    font-size: 60px;
    line-height: 60px;
  }
}
@media (max-width: 992px) {
  h2 {
    font-size: 56px;
    line-height: 56px;
  }
}
@media (max-width: 576px) {
  h2 {
    font-size: 42px;
    line-height: 42px;
  }
}
@media (max-width: 360px) {
  h2 {
    font-size: 36px;
    line-height: 36px;
  }
}

h3 {
  font-size: 56px;
  line-height: 56px;
}
@media (max-width: 1366px) {
  h3 {
    font-size: 34px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  h3 {
    font-size: 28px;
    line-height: 32px;
  }
}

h4 {
  font-size: 36px;
  line-height: 42px;
}
@media (max-width: 1366px) {
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  h4 {
    font-size: 20px;
    line-height: 32px;
  }
}

.container {
  max-width: 1674px;
  margin: 0 auto;
}
@media (max-width: 1366px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 576px) {
  .container {
    padding: 0 20px;
  }
}

.hero {
  height: 100vh;
  background: url("https://dev.yoho.pl/racoon/dist/images/racoon.png"), #BF0012;
  background-size: contain;
  background-position: bottom center;
  position: relative;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-blend-mode: multiply;
  color: white;
  text-align: center;
}
.hero::before {
  content: "";
  position: absolute;
  height: 36px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background: url("https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg"), no-repeat;
  background-size: cover;
  transform: rotate(180deg);
}
.hero__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 82px 0;
  gap: 100px;
}
@media (max-width: 1366px) {
  .hero__container {
    padding: 68px 0;
  }
}
@media (max-width: 768px) {
  .hero__container {
    padding: 90px 0 10px 0;
  }
}
@media (max-width: 360px) {
  .hero__container {
    padding: 45px 0 10px 0;
  }
}
.hero__title {
  font-family: "foco";
  font-weight: bold;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 32px;
}
@media (max-width: 1366px) {
  .hero__title {
    gap: 10px;
  }
}
@media (max-width: 576px) {
  .hero__title {
    gap: 30px;
  }
}
.hero__bottom-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: end;
  gap: 60px;
}
@media (max-width: 1366px) {
  .hero__bottom-wrap {
    gap: 92px;
  }
}
@media (max-width: 992px) {
  .hero__bottom-wrap {
    gap: 47px;
  }
}
@media (max-width: 768px) {
  .hero__bottom-wrap {
    gap: 20px;
  }
}
.hero__logo {
  max-width: 120px;
}
@media (max-width: 1366px) {
  .hero__logo {
    max-width: 90px;
  }
}
@media (max-width: 992px) {
  .hero__logo {
    max-width: 150px;
  }
}
@media (max-width: 360px) {
  .hero__logo {
    max-width: 120px;
  }
}
@media (max-width: 360px) {
  .hero__arrow {
    max-height: 80px;
  }
}

.slogan {
  padding: 224px 0 155px 0;
}
@media (max-width: 1366px) {
  .slogan {
    padding: 113px 0 87px 0;
  }
}
@media (max-width: 992px) {
  .slogan {
    padding: 209px 0 149px 0;
  }
}
@media (max-width: 576px) {
  .slogan {
    padding: 90px 0 67px 0;
  }
}
@media (max-width: 360px) {
  .slogan {
    padding: 80px 0 67px 0;
  }
}
.slogan__container {
  max-width: 1287px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
  gap: 63px;
  align-items: start;
}
@media (max-width: 1366px) {
  .slogan__container {
    margin-left: 15%;
    margin-right: 5%;
  }
}
@media (max-width: 992px) {
  .slogan__container {
    gap: 22px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 768px) {
  .slogan__container {
    flex-direction: column;
    align-items: start;
    width: fit-content;
  }
}
@media (max-width: 576px) {
  .slogan__container {
    padding: 0 15px;
    width: 100%;
  }
}
@media (max-width: 992px) {
  .slogan__image {
    max-width: 139px;
    gap: 28px;
  }
}
.slogan__title {
  font-family: "foco";
  font-weight: bold;
}
@media (max-width: 992px) {
  .slogan__title {
    max-width: none;
    width: fit-content;
  }
}

.slider {
  max-width: 1287px;
  margin: 0 auto;
  padding: 0 30px;
}
@media (max-width: 1366px) {
  .slider {
    max-width: 1144px;
  }
}
@media (max-width: 992px) {
  .slider {
    max-width: 632px;
  }
}
@media (max-width: 576px) {
  .slider {
    padding: 0 15px;
  }
}
.slider__slide {
  display: block;
  text-transform: uppercase;
  font-family: "nimbus-sans";
  font-size: 18px;
  text-align: center;
}
@media (max-width: 1366px) {
  .slider__slide {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .slider__slide {
    text-align: left;
  }
}
.slider__slide a {
  color: #BF0012;
}
.slider__slide span {
  font-weight: bold;
  color: #BF0012;
}
@media (max-width: 576px) {
  .slider__slide span {
    display: block;
    margin-top: 15px;
  }
}

.about-us {
  padding-top: 289px;
  padding-bottom: 178px;
}
@media (max-width: 1366px) {
  .about-us {
    padding-top: 110px;
    padding-bottom: 134px;
  }
}
@media (max-width: 992px) {
  .about-us {
    padding-top: 162px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 576px) {
  .about-us {
    padding-top: 48px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 37px;
  }
}
.about-us__container {
  max-width: 1287px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
  gap: 174px;
  align-items: start;
}
@media (max-width: 1366px) {
  .about-us__container {
    gap: 127px;
    max-width: 783px;
  }
}
@media (max-width: 992px) {
  .about-us__container {
    flex-direction: column;
    gap: 104px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0px;
    max-width: 632px;
  }
}
@media (max-width: 768px) {
  .about-us__container {
    flex-direction: column;
    align-items: start;
    width: fit-content;
  }
}
.about-us__left {
  max-width: 300px;
}
@media (max-width: 1366px) {
  .about-us__left {
    max-width: 205px;
  }
}
@media (max-width: 992px) {
  .about-us__left {
    max-width: 470px;
  }
}
.about-us__right {
  max-width: 616px;
}
@media (max-width: 1366px) {
  .about-us__right {
    max-width: 428px;
  }
}
@media (max-width: 992px) {
  .about-us__right {
    max-width: 632px;
  }
}
.about-us__heading {
  text-transform: uppercase;
  font-size: 16px;
  color: #BF0012 !important;
  font-family: "nimbus-sans";
  margin: 0;
  margin-bottom: 57px;
  min-height: 19px;
}
@media (max-width: 1366px) {
  .about-us__heading {
    margin-bottom: 42px;
  }
}
@media (max-width: 992px) {
  .about-us__heading {
    margin-bottom: 45px;
  }
}
@media (max-width: 576px) {
  .about-us__heading {
    margin-bottom: 32px;
  }
}
.about-us__paragraph {
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 1366px) {
  .about-us__paragraph {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  .about-us__paragraph {
    font-size: 16px;
    line-height: 26px;
  }
}
.about-us__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  align-items: start;
}
.about-us__subtitle {
  padding-bottom: 14px;
  font-size: 32px;
  padding-top: 0;
  margin: 0;
  font-weight: bold;
  line-height: 32px;
}
@media (max-width: 1366px) {
  .about-us__subtitle {
    font-size: 28px;
  }
}
@media (max-width: 992px) {
  .about-us__subtitle {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .about-us__subtitle {
    font-size: 28px;
    padding-bottom: 12px;
  }
}

.about-us-two {
  background-color: #272727;
}
.about-us-two * {
  color: white;
}
@media (max-width: 992px) {
  .about-us-two .about-us__container {
    gap: 0;
  }
}

.services {
  height: 100vh;
  background-color: #BF0012;
  position: relative;
  display: flex;
  align-items: end;
}
.services__container {
  padding-bottom: 180px;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1366px) {
  .services__container {
    padding-bottom: 58px;
  }
}
@media (max-width: 992px) {
  .services__container {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (max-width: 576px) {
  .services__container {
    padding-bottom: 50px;
  }
}
.services::before {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 60px;
  background-repeat: no-repeat;
  background: url("https://dev.yoho.pl/racoon/dist/images/wytarcia_pion_jasne.svg"), no-repeat;
  background-size: cover;
  background-position: right;
}
.services::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 60px;
  background-repeat: no-repeat;
  background: url("https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg"), no-repeat;
  background-size: cover;
}
.services * {
  color: white;
}
.services__category {
  padding-bottom: 22px;
  text-transform: uppercase;
  font-family: "nimbus-sans";
  font-size: 16px;
}
.services__name {
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
  max-width: 1326px;
}
@media (max-width: 1366px) {
  .services__name {
    font-size: 48px;
    line-height: 48px;
    max-width: 908px;
  }
}
@media (max-width: 576px) {
  .services__name {
    font-size: 28px;
    line-height: 28px;
  }
}
.services__name .italic {
  font-family: "sofia-pro", sans-serif;
  font-weight: 300;
  font-style: italic;
}
.services__name .regular {
  font-weight: 400;
}
.services__subname {
  font-weight: 400;
}
@media (max-width: 1366px) {
  .services__subname {
    font-size: 28px;
    line-height: 28px;
  }
}
@media (max-width: 576px) {
  .services__subname {
    font-size: 20px;
    line-height: 20px;
    max-width: 260px;
  }
}

.person-bright__container {
  max-width: 1248px;
  margin: 0 auto;
  padding-top: 174px;
  padding-bottom: 115px;
  gap: 107px;
  position: relative;
}
@media (max-width: 1366px) {
  .person-bright__container {
    gap: 77px;
    max-width: 872px;
    padding-top: 144px;
    padding-bottom: 68px;
  }
}
@media (max-width: 992px) {
  .person-bright__container {
    flex-direction: column;
    max-width: 632px;
    align-items: end;
    margin: 0 auto;
    padding-top: 178px;
    padding-bottom: 171px;
  }
}
@media (max-width: 992px) and (max-width: 992px) {
  .person-bright__container {
    padding-top: 92px;
  }
}
@media (max-width: 768px) {
  .person-bright__container {
    padding-top: 41px;
    padding-bottom: 40px;
    align-items: center;
    max-width: none;
    margin: 0 15px;
  }
}
.person-bright__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 170px;
}
@media (max-width: 1366px) {
  .person-bright__wrapper {
    gap: 102px;
  }
}
@media (max-width: 992px) {
  .person-bright__wrapper {
    flex-direction: column;
    align-items: end;
  }
}
@media (max-width: 768px) {
  .person-bright__wrapper {
    align-items: center;
  }
}
.person-bright__left {
  position: relative;
  width: 100%;
}
@media (max-width: 992px) {
  .person-bright__left {
    width: auto;
  }
}
@media (max-width: 576px) {
  .person-bright__left {
    display: none;
  }
}
.person-bright__left--mobile {
  display: none;
  min-height: 500px;
  width: 80%;
  position: relative;
  align-self: end;
  background-position: center !important;
}
@media (max-width: 576px) {
  .person-bright__left--mobile {
    display: block;
  }
}
@media (max-width: 420px) {
  .person-bright__left--mobile {
    min-height: 400px;
  }
}
.person-bright__name-holder {
  display: flex;
  align-items: end;
  gap: 30px;
  padding-top: 29px;
  margin-bottom: 48px;
}
@media (max-width: 1366px) {
  .person-bright__name-holder {
    gap: 18px;
  }
}
@media (max-width: 992px) {
  .person-bright__name-holder {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .person-bright__name-holder {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .person-bright__name-holder {
    lefT: 0;
    padding-top: 0;
  }
}
.person-bright__name {
  font-weight: 700;
}
@media (max-width: 1366px) {
  .person-bright__name {
    font-size: 38px;
    line-height: 38px;
  }
}
@media (max-width: 992px) {
  .person-bright__name {
    font-size: 60px;
    line-height: 50px;
  }
}
@media (max-width: 768px) {
  .person-bright__name {
    font-size: 42px;
    line-height: 61px;
  }
}
.person-bright__position {
  font-family: "tomarik-poster";
  margin: 0;
  font-size: 20px;
}
@media (max-width: 768px) {
  .person-bright__position {
    padding-left: 30px;
  }
}
.person-bright__spin {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 30px;
  z-index: 120;
  font-family: "sofia-pro";
  color: #BF0012;
  font-size: 16px;
}
@media (max-width: 992px) {
  .person-bright__spin {
    left: 0;
  }
}
@media (max-width: 768px) {
  .person-bright__spin {
    display: none;
  }
}
.person-bright__spin span {
  padding-bottom: 32px;
}
.person-bright__right {
  max-width: 458px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 76px;
  gap: 12px;
}
.person-bright__right p {
  font-size: 16px;
  line-height: 26px;
}
@media (max-width: 1366px) {
  .person-bright__right p {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  .person-bright__right p {
    font-size: 16px;
    line-height: 26px;
  }
}
@media (max-width: 1366px) {
  .person-bright__right {
    max-width: 317px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 992px) {
  .person-bright__right {
    max-width: 466px;
  }
}
@media (max-width: 768px) {
  .person-bright__right {
    max-width: none;
  }
}
.person-bright__image {
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  max-height: 667px;
}
@media (max-width: 1366px) {
  .person-bright__image {
    max-height: 458px;
  }
}
@media (max-width: 992px) {
  .person-bright__image {
    position: static;
    max-height: 678px;
  }
}
@media (max-width: 768px) {
  .person-bright__image {
    max-height: 90vw;
  }
}
@media (max-width: 576px) {
  .person-bright__image {
    width: 80%;
    object-fit: cover;
    max-height: none;
  }
}
.person-bright__linkedin {
  position: absolute;
  bottom: 0;
  right: -57px;
  z-index: 101;
  transform: translate3d(0, 0, 0);
}
@media (max-width: 1366px) {
  .person-bright__linkedin {
    bottom: 100px;
  }
}
@media (max-width: 992px) {
  .person-bright__linkedin {
    left: -57px;
    bottom: -50px;
  }
}
@media (max-width: 576px) {
  .person-bright__linkedin {
    left: -36px;
  }
}
.person-bright__linkedin img {
  width: 117px;
  height: 117px;
}
@media (max-width: 1366px) {
  .person-bright__linkedin img {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 992px) {
  .person-bright__linkedin img {
    width: 117px;
    height: 117px;
  }
}
@media (max-width: 576px) {
  .person-bright__linkedin img {
    width: 78px;
    height: 78px;
  }
}

.person-dark--desktop {
  background-color: #272727;
}
@media (max-width: 992px) {
  .person-dark--desktop {
    display: none;
  }
}
.person-dark--desktop * {
  color: white;
}
.person-dark--desktop .person-bright__container {
  gap: 174px;
  display: flex;
}
@media (max-width: 1366px) {
  .person-dark--desktop .person-bright__container {
    gap: 127px;
  }
}
.person-dark--desktop .person-bright__right {
  max-width: none;
  align-items: end;
}
.person-dark--desktop .person-bright__right p {
  max-width: 458px;
}
@media (max-width: 1366px) {
  .person-dark--desktop .person-bright__right p {
    max-width: 317px;
  }
}
.person-dark--desktop .person-bright__right h2, .person-dark--desktop .person-bright__right h5 {
  white-space: nowrap;
}
.person-dark--desktop .person-bright__left {
  height: fit-content;
}
.person-dark--desktop .person-bright__name-holder {
  padding-bottom: 78px;
  padding-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1366px) {
  .person-dark--desktop .person-bright__name-holder {
    padding-bottom: 40px;
  }
}
.person-dark--desktop .person-bright__image {
  z-index: 0;
  position: static;
}
.person-dark--desktop .person-bright__linkedin {
  right: unset;
  left: -57px;
  bottom: -61px;
}

.person-dark--mobile {
  background-color: #272727;
  display: none !important;
}
@media (max-width: 992px) {
  .person-dark--mobile {
    display: block !important;
  }
}
.person-dark--mobile * {
  color: white;
}
@media (max-width: 992px) {
  .person-dark--mobile .person-bright__container {
    padding-top: 92px;
  }
}
@media (max-width: 768px) {
  .person-dark--mobile .person-bright__container {
    padding-top: 41px;
  }
}
.person-dark--mobile .person-bright__name-holder {
  top: -130px;
}
@media (max-width: 992px) {
  .person-dark--mobile .person-bright__name-holder {
    align-self: start;
  }
}
@media (max-width: 768px) {
  .person-dark--mobile .person-bright__name-holder {
    width: 100%;
    align-items: center;
    left: 0;
  }
}
@media (max-width: 768px) {
  .person-dark--mobile .person-bright__position {
    padding-right: 30px;
  }
}
.person-dark--mobile .person-bright__image {
  z-index: 0;
}
@media (max-width: 576px) {
  .person-dark--mobile .person-bright__image {
    max-height: none;
    width: 80vw;
  }
}
@media (max-width: 992px) {
  .person-dark--mobile .person-bright__right {
    margin-left: auto;
    margin-top: 110px;
  }
}
@media (max-width: 992px) {
  .person-dark--mobile .person-bright__left {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: min-content;
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .person-dark--mobile .person-bright__left {
    width: 100%;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 576px) {
  .person-dark--mobile .person-bright__left {
    justify-content: start;
    align-items: end;
  }
}
@media (max-width: 768px) {
  .person-dark--mobile .person-bright__linkedin {
    left: unset;
    right: 77px;
  }
}
@media (max-width: 576px) {
  .person-dark--mobile .person-bright__linkedin {
    left: -35px;
    right: unset;
  }
}
@media (max-width: 576px) {
  .person-dark--mobile .person-bright__wrap-mobile {
    position: relative;
    text-align: right;
    width: 80vw;
  }
}

.footer {
  height: 100vh;
  background: url("https://dev.yoho.pl/racoon/dist/images/racoon-small.png"), #BF0012;
  background-size: 157px 235px, contain;
  background-position: bottom center;
  position: relative;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-blend-mode: multiply;
  text-align: center;
}
@media (max-width: 576px) {
  .footer {
    background-position: bottom right;
  }
}
.footer * {
  color: white;
}
.footer::before {
  content: "";
  position: absolute;
  height: 46px;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background: url("https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg"), no-repeat;
  background-size: cover;
  background-position: top;
  filter: brightness(0);
}
.footer::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 66px;
  height: 100%;
  background-repeat: no-repeat;
  background: url("https://dev.yoho.pl/racoon/dist/images/wytarcia_pion_jasne.svg"), no-repeat;
  background-size: cover;
  background-position: top;
  filter: brightness(0);
}
.footer__container {
  max-width: 1563px;
  padding: 0 25px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 50px;
}
@media (max-width: 1366px) {
  .footer__container {
    max-width: 1090px;
    padding: 0 25px;
  }
}
.footer__content {
  display: flex;
  gap: 137px;
  justify-content: center;
  text-align: center;
  align-items: start;
}
@media (max-width: 1366px) {
  .footer__content {
    gap: 100px;
    padding-top: 200px;
  }
}
@media (max-height: 700px) {
  .footer__content {
    padding-top: 100px;
  }
}
@media (max-width: 992px) {
  .footer__content {
    gap: 49px;
  }
}
@media (max-width: 576px) {
  .footer__content {
    flex-direction: column;
    gap: 32px;
    text-align: left;
    padding-top: 115px;
  }
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
  padding-bottom: 77px;
  align-items: end;
}
@media (max-width: 1366px) {
  .footer__bottom {
    padding-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .footer__bottom {
    padding-bottom: 71px;
    padding-top: 11px;
  }
}
@media (max-width: 576px) {
  .footer__bottom {
    flex-direction: column;
    align-items: start;
  }
}
@media (max-width: 1366px) {
  .footer__logo {
    max-width: 121px;
  }
}
@media (max-width: 992px) {
  .footer__logo {
    max-width: 144px;
  }
}
@media (max-width: 768px) {
  .footer__logo {
    max-width: 106px;
  }
}
.footer__title {
  font-size: 85px;
  line-height: 80px;
  padding-top: 40px;
}
@media (max-width: 1366px) {
  .footer__title {
    font-size: 48px;
    line-height: 48px;
    padding-top: 0;
  }
}
@media (max-width: 992px) {
  .footer__title {
    font-size: 72px;
    line-height: 80px;
  }
}
@media (max-width: 576px) {
  .footer__title {
    font-size: 36px;
    line-height: 36px;
    padding-top: 0;
  }
}
.footer__mail, .footer__phone {
  font-size: 24px;
  display: block;
  padding-bottom: 21px;
  font-weight: bold;
  text-align: left;
}
@media (max-width: 1366px) {
  .footer__mail, .footer__phone {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .footer__mail, .footer__phone {
    font-size: 24px;
    padding-bottom: 21px;
  }
}
@media (max-width: 576px) {
  .footer__mail, .footer__phone {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
.footer__mail {
  padding-bottom: 43px;
  font-size: 28px;
}
@media (max-width: 576px) {
  .footer__mail {
    font-size: 18px;
    padding-bottom: 20px;
  }
}
.footer__phone {
  display: inline-block;
  margin: 0;
  line-height: 35px;
  padding: 0;
  font-size: 24px;
  font-weight: 400;
}
.footer__phone span {
  font-weight: 400;
}
@media (max-width: 576px) {
  .footer__phone {
    display: block;
    font-size: 18px;
  }
}
.footer__phones {
  display: flex;
  flex-direction: column;
}
.footer__slash {
  padding: 0 25px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 24px;
}
@media (max-width: 576px) {
  .footer__slash {
    display: none;
  }
}
.footer__policy {
  font-size: 8px;
  position: absolute;
  bottom: 28px;
  left: 25px;
}
@media (max-width: 576px) {
  .footer__policy {
    right: unset;
    left: 25px;
  }
}
.footer__createdby {
  font-size: 12px;
  position: absolute;
  bottom: 28px;
  right: 25px;
}
.footer__contact-right {
  font-size: 24px;
  font-weight: 400;
  text-align: right;
  line-height: 35px;
}
@media (max-width: 1366px) {
  .footer__contact-right {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .footer__contact-right {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .footer__contact-right {
    font-size: 16px;
    text-align: left;
    padding-top: 15px;
  }
}

:root {
  --bar-bg: #212529;
}

.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #F6F5F4;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #BF0012;
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active {
  background-color: transparent !important;
}
.menu-icon.active span {
  background-color: white !important;
}
.menu-icon.active span:first-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 5px;
}
.menu-icon.active span:last-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}
.menu-icon input {
  margin: 0;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
  }
  .menu-icon:hover span:last-of-type {
    width: 12px;
  }
}

.nav--white .menu-icon {
  background-color: #BF0012;
}
.nav--white .menu-icon span {
  background-color: #F6F5F4;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.nav {
  position: fixed;
  z-index: 103;
  padding: 20px;
  display: flex;
  justify-content: end;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}
.nav--white {
  background-color: #F6F5F4;
}
.nav .demo {
  transform: translateY(4px);
}
.nav__logo {
  pointer-events: none;
  left: 100px;
  position: absolute;
  top: 9px;
}
@media (max-width: 992px) {
  .nav__logo {
    left: 20px;
  }
}
.nav__lang-wrap {
  background-color: rgba(255, 255, 255, 0.1450980392);
  padding: 10px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  border-radius: 32px;
}
.nav__list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  gap: 40px;
  border-radius: 32px;
  background-color: transparent;
  position: absolute;
  margin: 0;
  right: 17px;
  padding-right: 0px;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.5s ease, padding 0.5s ease, background-color 0.5s ease;
}
@media (max-width: 768px) {
  .nav__list {
    gap: 20px;
  }
}
@media (max-width: 576px) {
  .nav__list {
    flex-direction: column;
  }
}
.nav__list--active {
  padding: 8px 37px;
  background-color: #BF0012;
  padding-right: 55px;
  max-width: 539px;
}
.nav__link {
  text-transform: uppercase;
  color: white;
}
@media (max-width: 768px) {
  .nav__link {
    font-size: 14px;
  }
}

.nav--white .nav__logo {
  pointer-events: all;
}

.section-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 101;
  gap: 20px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  top: 24px;
  left: 20px;
}
@media (max-width: 1366px) {
  .section-nav {
    top: 22px;
  }
}
@media (max-width: 992px) {
  .section-nav {
    display: none;
  }
}
.section-nav__link {
  font-family: tomarik-poster;
  font-size: 18px;
  color: black;
}

.eng .nav__list--active {
  padding-right: 90px;
}