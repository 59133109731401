.footer {
    height:100vh;
    background:url('https://dev.yoho.pl/racoon/dist/images/racoon-small.png'), $red;
    background-size:157px 235px, contain, ;  
    background-position: bottom center;
    position: relative;
    background-repeat: no-repeat, no-repeat,no-repeat;
    background-blend-mode: multiply;
    text-align: center;
    @media(max-width:$sm ){
        background-position: bottom right;
    }
    * {
        color:white;

    }
    &::before {
        content: "";
        position: absolute;
        height: 46px;
        top: 0;
        left: 0;
        width: 100%;
        background-repeat: no-repeat;
        background: url('https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg'), no-repeat;
        background-size: cover;
        background-position: top;
        filter: brightness(0);
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 66px;
        height: 100%;
        background-repeat: no-repeat;
        background: url('https://dev.yoho.pl/racoon/dist/images/wytarcia_pion_jasne.svg'), no-repeat;
        background-size: cover;
        background-position: top;
        filter: brightness(0);
    }
    &__container {
        max-width: 1563px;
        padding: 0 25px;
        height: 100%;
        margin: 0 auto;
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: end;
        gap:50px;
        @media(max-width:$lg ){
            max-width: 1090px;
            padding: 0 25px;
        }
    }
    &__content {
        display: flex;
        gap:137px;
        justify-content: center;
        text-align: center;
        align-items: start;
        @media(max-width:$lg ){
            gap:100px;
            padding-top: 200px;
        }
        @media(max-height:700px) {
            padding-top:100px;
        }
        @media(max-width:$md ){
           gap:49px;
        }
        @media(max-width:$sm){
            flex-direction: column;
            gap:32px;
            text-align: left;
            padding-top: 115px;
         }
    }
    &__bottom {
        display: flex;
        justify-content: space-between;
        padding-bottom: 77px;
        align-items: end;
         @media(max-width:$lg ){
            padding-bottom: 50px;
         }
        @media(max-width:$md ){
            padding-bottom: 71px;
            padding-top: 11px;
         }
         @media(max-width:$sm ){
            flex-direction: column;
            align-items: start;
        }
    }
    &__logo {
        @media(max-width:$lg ){
            max-width: 121px;
        }
        @media(max-width:$md ){
            max-width: 144px;
        }
        @media(max-width:$mds ){
            max-width: 106px;
        }
    }
    &__title {
        font-size: 85px;
        line-height: 80px;
        padding-top: 40px;
        @media(max-width:$lg ){
            font-size: 48px;
            line-height: 48px;
            padding-top: 0;
        }
        @media(max-width:$md ){
            font-size: 72px;
        line-height: 80px;
         }
         @media(max-width:$sm ){
            font-size: 36px;
        line-height: 36px;
        padding-top: 0;

        }
    }
    &__mail,&__phone {
        font-size: 24px;
        display: block;
        padding-bottom: 21px;
        font-weight: bold;
        text-align: left;
        @media(max-width:$lg ){
            font-size: 18px;
            padding-bottom: 10px;
        }
        @media(max-width:$md ){
            font-size: 24px;
            padding-bottom: 21px;

         }
         @media(max-width:$sm ){
            font-size: 18px;
            padding-bottom: 10px;
        }
    }
    &__mail {
        padding-bottom: 43px;
        font-size: 28px;;
        @media(max-width:$sm) {
            font-size: 18px;
            padding-bottom: 20px;
        }
    }
    &__phone {
        display: inline-block;
        margin: 0;
        line-height: 35px;
        padding: 0;
        font-size: 24px;
        font-weight: 400;
        span {
            font-weight: 400;
        }
        @media(max-width:$sm ){
            display: block;
            font-size: 18px;
        }
    }
    &__phones {
        display: flex;
        flex-direction: column;
    }
    &__slash {
        padding:0 25px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 24px;
        @media(max-width:$sm ){
            display: none;
        }
    }
    &__policy {
        font-size: 8px;
        position: absolute;
        bottom:28px;
        left: 25px;;
        @media(max-width:$sm ){
            right:unset;
            left:25px;
        }
    }
    &__createdby {
        font-size: 12px;
        position: absolute;
        bottom:28px;
        right: 25px;;
       
    }
    &__contact-right {
        font-size: 24px;
        font-weight: 400;
        text-align: right;
        line-height: 35px;
        @media(max-width:$lg ){
            font-size: 16px;
        }
        @media(max-width:$md ){
            font-size: 18px;


         }
         @media(max-width:$sm ){
            font-size: 16px;
            text-align: left;
            padding-top: 15px;
        }
    }
}