
.nav {
    position: fixed;;
    z-index: 103;
    padding: 20px;
    display: flex;
    justify-content: end;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    transition: background-color .3s ease-in-out;
    &--white {
        background-color: #F6F5F4;

    }
    .demo {
        transform: translateY(4px);
    }
    &__logo {
        pointer-events: none;
        left:100px;
        position: absolute;
        top:9px;
        @media(max-width:$md) {
            left:20px;
        }
    }
    &__lang-wrap {
        background-color: #ffffff25;
        padding:10px 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:13px;
        border-radius: 32px;
    }
    &__list {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
        gap: 40px;
        border-radius: 32px;
        background-color: transparent;
        position: absolute;
        margin: 0;
        right: 17px;
        padding-right: 0px;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;  // Prevent children from wrapping
        transition: max-width .5s ease, padding .5s ease, background-color .5s ease;
        @media(max-width:$mds) {
            gap:20px;
        }
        @media(max-width:$sm) {
            flex-direction: column;
        }
        &--active {
            padding: 8px 37px;
            background-color: $red;
            padding-right: 55px;
            max-width: 539px; // Adjust this value as per your actual max width
        }
    }
    &__link {
        text-transform: uppercase;
        color: white;
        @media(max-width:$mds) {
            font-size: 14px;
        }
    }
    
    
}
.nav--white {
    .nav__logo {

        pointer-events: all;

    }
}
.section-nav {
    @include flex-center;
    justify-content: space-between;
    position: fixed;
    z-index: 101;
    gap:20px;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    top:24px;
    left:20px;
    @media(max-width:$lg) {
        top:22px;
    }
    @media(max-width:$md) {
        display: none;
    }

    &__link {
        font-family: tomarik-poster;
        font-size: 18px;
        color:black;
    }
}
.eng {
    .nav__list--active {
        padding-right: 90px;
    }
}