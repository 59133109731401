
html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
body {
    margin:0; 
    padding:0;
    overflow-x: hidden;
    background:#F6F5F4;
    background-size: contain;
}
* {
    font-family: $foco;
}
p {
    margin:0;
}
a {
    color:black;
    text-decoration: none;
}
div {
    box-sizing: border-box;
    margin: 0;
    padding:0;
}
h1,h2,h3,h4 {
    margin: 0;
    padding:0;
}
::selection {
    background-color: #bf001377;
    color:black;
}
body::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: black;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: $red;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid black;  /* creates padding around scroll thumb */
  }