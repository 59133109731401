.hero {
    height:100vh;
    background:url('https://dev.yoho.pl/racoon/dist/images/racoon.png'), $red;
    background-size: contain;  
    background-position: bottom center;
    position: relative;
    background-repeat: no-repeat, no-repeat,no-repeat;
    background-blend-mode: multiply;
    color:white;
    text-align: center;
    &::before {
        content: "";
        position: absolute;
        height: 36px;
        bottom: 0;
        left: 0;
        width: 100%;
        background-repeat: no-repeat;
        background: url('https://dev.yoho.pl/racoon/dist/images/wytarcia_poziom_jasne.svg'), no-repeat;
        background-size: cover;
        transform: rotate(180deg);
    }
    &__container {
        @include flex-center;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding:82px 0;
        gap: 100px;
        @media(max-width:$lg) {
            padding:68px 0;
        }
        @media(max-width:$mds) {
            padding:90px 0 10px 0;
        }
        @media(max-width:$xsm) {
            padding:45px 0 10px 0;
        }
    }
    &__title {
        font-family: $foco;
        font-weight: bold;
        padding:0 30px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap:32px;
        @media(max-width:$lg) {
          
                gap:10px;
     
        }
        @media(max-width:$sm) {
            gap:30px;
        }
    }
    &__bottom-wrap {
        @include flex-center;
        flex-direction: column;
        justify-content: end;
        gap:60px;
        @media(max-width:$lg) {
            gap:92px;
        }
        @media(max-width:$md) {
            gap:47px;
        }
         @media(max-width:$mds) {
          
           gap:20px;
     
        }
    }
    &__logo {
        max-width: 120px;
        @media(max-width:$lg) {
            max-width: 90px;
        }
        @media(max-width:$md) {
            max-width: 150px;
        }
        @media(max-width:$xsm) {
            max-width: 120px;
        }
    }
    &__arrow {
        @media(max-width:$xsm) {
            max-height: 80px;
        }
    }
}