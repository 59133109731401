.slider {
    max-width: 1287px;
    margin: 0 auto;
    padding:0 30px;
    @media(max-width:$lg) {
        max-width: 1144px;
    }
    @media(max-width:$md) {
        max-width: 632px;
    }
    @media(max-width:$sm) {
        padding:0 15px;
    }
    &__slide {
        display: block;
        text-transform: uppercase;
        font-family: $nimbus;
        font-size: 18px;
        text-align: center;
        @media(max-width:$lg) {
            font-size: 16px;
        }
        @media(max-width:$md) {
            text-align: left;
        }
        a {
            color:$red;
        }
        span {
            font-weight: bold;
            color:$red;
            @media(max-width:$sm) {
                display: block;
                margin-top: 15px;
            }
        }
    }
}