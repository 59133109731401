h1 {
    font-size: 92px;
    line-height: 92px;
    @media(max-width:$lg){
        font-size: 72px;
        line-height: 72px;
    }
    @media(max-width:$mds){
        font-size: 38px;
        line-height: 38px;
    }
    @media(max-width:$xsm) {
        font-size: 32px;
        line-height: 32px;
    }
   
}
h2 { 
    font-size: 60px;
    line-height: 60px;
    @media(max-width:$lg){
        font-size: 60px;
        line-height: 60px;
    }
    @media(max-width:$md){
        font-size: 56px;
        line-height: 56px;
    }
    @media(max-width:$sm){
        font-size: 42px;
        line-height: 42px;
    }
    @media(max-width:$xsm){
        font-size: 36px;
        line-height: 36px;
    }
   
}
h3 {
    font-size: 56px;
    line-height: 56px;
    @media(max-width:$lg){
        font-size: 34px;
        line-height: 32px;
    }
    @media(max-width:$md){
        font-size: 28px;
        line-height: 32px;
    }
   
}
h4 {
    font-size: 36px;
    line-height: 42px;
    @media(max-width:$lg){
        font-size: 20px;
        line-height: 32px;
    } 
    @media(max-width:$md){
        font-size: 20px;
        line-height: 32px;
    }
   
}