.slogan {
    padding:224px 0 155px 0;
    @media(max-width:$lg) {
        padding:113px 0 87px 0;
    }
    @media(max-width:$md) {
        padding: 209px 0 149px 0;
    }
    @media(max-width:$sm) {
        padding: 90px 0 67px 0;
    }
    @media(max-width:$xsm) {
        padding: 80px 0 67px 0;
    }
    &__container {
        max-width: 1287px;
        margin: 0 auto;
        @include flex-center;
        justify-content: start;
        gap:63px;
        align-items: start;
        @media(max-width:$lg) {
            margin-left: 15%;
            margin-right: 5%;
        }
        @media(max-width:$md) {
            gap:22px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
        }
        @media(max-width:$mds) {
            flex-direction: column;
            align-items: start;
            width: fit-content;
        }
         @media(max-width:$sm) {
            padding:0 15px;
            width: 100%;
        }
    }
    &__image {
        @media(max-width:$md) {
            max-width:139px;
            gap:28px;
        }
    }
    &__title {
        font-family:$foco;
        font-weight: bold;
        @media(max-width:$md) {
          
            max-width:none;
            width: fit-content;
        }
    }
}