.person-bright {
    &__container {
        max-width: 1248px;
        margin:0 auto;
        padding-top: 174px;
        padding-bottom: 115px;
        gap:107px;
        position: relative;
        @media(max-width:$lg) {
            gap:77px;
            max-width: 872px;
            padding-top: 144px;
            padding-bottom: 68px;
        }
        @media(max-width:$md) {
            flex-direction: column;
            max-width: 632px;
            align-items: end;
            margin: 0 auto;;
            padding-top: 178px;
            padding-bottom: 171px;
            @media(max-width:$md) {
                padding-top: 92px;
            }
        }
        @media(max-width:$mds) {
            padding-top: 41px;
            padding-bottom: 40px;
            align-items: center;
            max-width: none;
            margin: 0 15px;
        }
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        gap:170px;
        @media(max-width:$lg) {
            gap:102px;
        }
        @media(max-width:$md) {
            flex-direction: column;
            align-items: end;
        }
        @media(max-width:$mds) {
            align-items: center;
        }
    }
    &__left {
        position: relative;
        width: 100%;
        @media(max-width:$md) {
            width: auto;
        }
        @media(max-width:$sm) {
            display: none;
            


        }

    }
    &__left--mobile {
        display: none;
        min-height: 500px;
        width: 80%;
        position: relative;
        align-self: end;
        background-position: center!important;;
        @media(max-width:$sm) {
            display: block;;
        }
        @media(max-width:420px) {
            min-height: 400px;
        }
    }
    &__name-holder {
        display: flex;
        align-items: end;
        gap:30px;
        padding-top:29px;
        margin-bottom: 48px;
        @media(max-width:$lg) {
            gap: 18px;
        }
        @media(max-width:$md) {
            padding-top: 0;
        }
        @media(max-width:$mds) {
            display: flex;
            flex-direction: column;
            gap:0;
            align-items: center;
        }
        @media(max-width:$sm) {
            lefT:0;
            padding-top: 0;
        }
    }
    &__name {
        font-weight: 700;
        @media(max-width:$lg) {
            font-size: 38px;
            line-height: 38px;
        }
        @media(max-width:$md) {
            font-size: 60px;
            line-height: 50px;
        }
        @media(max-width:$mds) {
            font-size: 42px;
            line-height: 61px;
        }
    }
    &__position {
        font-family: $tomarik;
        margin: 0;;
        font-size: 20px;
        @media(max-width:$mds) {
            padding-left: 30px;
        }
    }
    &__spin {
        position: absolute;
        display: flex;
        flex-direction: column;
        bottom: 30px;
        z-index: 120;
        font-family: "sofia-pro";
        color:$red;
        font-size: 16px;
        @media(max-width:$md) {
            left:0;
        }
        @media(max-width:$mds) {
            display: none;
         }
        span {
            padding-bottom: 32px;
        }
    }
        
    
    &__right {
        max-width: 458px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 76px;
        gap:12px;
        p{
            font-size: 16px;
            line-height: 26px;
            @media(max-width:$lg) {
                font-size: 14px;
                line-height: 20px;
            }
            @media(max-width:$md) {
                font-size: 16px;
                line-height: 26px;
            }
        }
        @media(max-width:$lg) {
            max-width: 317px;
            padding-top: 0;
            padding-bottom: 0;
        }
        @media(max-width:$md) {
            max-width: 466px;
            
        }
        @media(max-width:$mds) {
            max-width: none;
        }
    }
    &__image {
        position: absolute;
        top:0;
        z-index: -1;
        right:0;
        max-height: 667px;
        @media(max-width:$lg) {
            max-height: 458px;
        }
        @media(max-width:$md) {
            position: static;
            max-height: 678px;
        }
        @media(max-width:$mds) {
            max-height: 90vw;

        }
        @media(max-width:$sm) {
            width: 80%;
            object-fit: cover;
            max-height: none;;
        }
        
    }
    &__linkedin {
        position:absolute;
        bottom: 0;
        right: -57px;
        z-index: 101;
        transform: translate3d(0,0,0);

        @media(max-width:$lg) {
            bottom:100px;
        }
        @media(max-width:$md) {
            left: -57px;
            bottom: -50px;
        }
        @media(max-width:$sm) {
            left: -36px;

        }
        
        img {
            width: 117px;
            height: 117px;
            @media(max-width:$lg) {
                width: 100px;
                height: 100px;
            }
            @media(max-width:$md) {
                width: 117px;
                height: 117px;
            }
            @media(max-width:$sm) {
                width: 78px;
                height: 78px;
                
            }
        }
    }
}
.person-dark--desktop {
    background-color: $black;
  

        @media(max-width:$md){
            display: none;

        }
    
    * {
        color:white;
    }
    .person-bright__container {
        gap: 174px;
        display: flex;
        @media(max-width:$lg) {
            gap:127px;
        }
       
    }
    .person-bright__right {
        max-width: none;
        align-items: end;
        p {
            max-width: 458px;
            @media(max-width:$lg) {
                max-width: 317px;
            }
        }
        h2,h5 {
            white-space:nowrap;
        }
    }
    .person-bright__left {
        height:fit-content;
       
    }
    .person-bright__name-holder {
        padding-bottom: 78px;
        padding-top:0;
        margin-bottom: 0;
       
        @media(max-width:$lg) {
            padding-bottom: 40px;
        }
    }
    .person-bright__image {
        z-index: 0;
        position: static;
    }
    .person-bright__linkedin {
        right:unset;
        left: -57px;
    bottom: -61px;
    }
}
.person-dark--mobile {
    background-color: $black;
    display: none!important;
    @media(max-width:$md){
        display: block!important;

    }
    * {
        color:white;
    }
    .person-bright__container {
        @media(max-width:$md) {
            padding-top:92px;
        }
        @media(max-width:$mds) {
            padding-top:41px;
        }
      
    }
    
    .person-bright__name-holder {
        top:-130px;
        @media(max-width:$md) {
            align-self: start;
        }
        @media(max-width:$mds) {
            width: 100%;
            align-items: center;;
            left: 0;
        }
    }
    .person-bright__position {
        @media(max-width:$mds) {
            padding-right: 30px;
        }
    }
    .person-bright__image {
        z-index: 0;
        @media(max-width:$sm) {
           max-height: none;
               width: 80vw;
        }
    }
    .person-bright__right {
        @media(max-width:$md) {
            margin-left: auto;
            margin-top: 110px;    
        }
    }
    .person-bright__left {
        @media(max-width:$md) {
            display: flex;
            flex-direction: column;
            align-items: end;
            width: min-content;
            margin-left: auto;
        }
        @media(max-width:$mds) {
           width: 100%;
           align-items: center;
           text-align: center;
        }
        @media(max-width:$sm) {
            justify-content: start;
            align-items: end;
         }
    }
    .person-bright__linkedin {
        @media(max-width:$mds) {
            left: unset;
             right: 77px;
        }
        @media(max-width:$sm) {
            left: -35px;
            right: unset;
        }
       
    }
    .person-bright__wrap-mobile {
        @media(max-width:$sm) {
           position: relative;
           text-align: right;
    width: 80vw;
        }
    }
}