.about-us {
    padding-top:289px;
    padding-bottom: 178px;
   
    @media(max-width:$sm) {
    }
    @media(max-width:$lg) {
        padding-top:110px;
        padding-bottom: 134px;
    }
    @media(max-width:$md) {
        padding-top:162px;
        padding-left:30px;
        padding-right:30px;
    }
    @media(max-width:$sm) {
        padding-top:48px;
        padding-left:15px;
        padding-right:15px;
        padding-bottom: 37px;

    }
    &__container {
        max-width: 1287px;
        margin: 0 auto;
        padding:0 30px;
        @include flex-center;
        justify-content: start;
        gap:174px;
        align-items: start;
        @media(max-width:$lg) {
           gap:127px;
           max-width: 783px;
        }   
        @media(max-width:$md) {
            flex-direction: column;
            gap:104px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            padding:0 0px;
            max-width: 632px;
        }
        @media(max-width:$mds) {
            flex-direction: column;
            align-items: start;
            width: fit-content;
        }
        
    }
    &__left {
        max-width: 300px;
        @media(max-width:$lg) {
            max-width: 205px;
        }
        @media(max-width:$md) {
            max-width: 470px;
        }
    }
    &__right {
        max-width: 616px;
        @media(max-width:$lg) {
            max-width: 428px;
        }
        @media(max-width:$md) {
            max-width: 632px;
        }
    }
    &__heading {
        text-transform: uppercase;
        font-size: 16px;
        color:$red!important;
        font-family: $nimbus;
        margin:0;
        margin-bottom: 57px;
        min-height: 19px;
        @media(max-width:$lg) {
            margin-bottom: 42px;
        }
        @media(max-width:$md) {
            margin-bottom: 45px;
        }
        @media(max-width:$sm) {
            margin-bottom: 32px;
        }

    }
    &__paragraph {
        font-size: 16px;
        line-height: 26px;
        @media(max-width:$lg) {
            font-size: 14px;
            line-height: 20px;        }
        @media(max-width:$md) {
            font-size: 16px;
            line-height: 26px;
            }
    }
    &__content {
        @include flex-center;
        flex-direction: column;
        justify-content: space-between;
        gap:32px;
        align-items: start;
    }
    &__subtitle {
        padding-bottom: 14px;
        font-size: 32px;
        padding-top: 0;
        margin: 0;
        font-weight: bold;
        line-height: 32px;
        @media(max-width:$lg) {
            font-size: 28px;
        }
        @media(max-width:$md) {
            font-size: 32px;
        }
        @media(max-width:$sm) {
            font-size: 28px;
            padding-bottom: 12px;
        }
    }
}
.about-us-two {
    background-color: $black;
   
    * {
        color:white;
    }
    .about-us__container {
        @media(max-width:$md) {
            gap:0;
        }
    }
}